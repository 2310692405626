import { ArrowBackIos, Circle, GroupOutlined, ListAlt, Payment, PinDropOutlined } from "@mui/icons-material";
import { Box, Button, Checkbox, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { SuccessAlert } from "../common/snackbar/success";
import { ErrorAlert } from "../common/snackbar/error";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import imagePlaceHolder from './../../images/img-placeholder.svg';
import { HttpRequest } from "../HttpRequest";
import capitalizeFirstLetter from "../common/capitalize";
import secureLocalStorage from "react-secure-storage";
import OrderStatus from "./orderStatus";
import { getOrderStatusColor } from "./orderStatusColor";


const OrderDetails = () => {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const navigate = useNavigate();
    const { orderId } = useParams();
    const [order, setOrder] = useState({});
    const [customer, setCustomer] = useState({});
    const [loading, setLoading] = useState(false);
    const [pushyId, setPushyId] = useState('');
    const [orderPaymentDetails, setOrderPaymentDetails] = useState({});
    const [selected, setSelected] = useState([]);
    const isSelected = (id) => selected.indexOf(id) !== -1;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${month} ${day}, ${year} ${formattedHours}:${formattedMinutes}${ampm}`;
    };
    const getOrderDetails = () => {
        setLoading(true);
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/getzedecommerce-order-byid?orderId=${orderId}`,
        }).then((data) => {
            if (data.status === 200) {
                setOrder(data.response.data);
                setPushyId()
            }
        }).catch((error) => {
        }).finally(() => {
            setLoading(false);
        })
    }
    const getCustomerDetails = () => {
        HttpRequest({
            method: 'GET',
            url: `/api/get_customer_by_customer_id?customerId=${order?.customerId ? order?.customerId:order?.userId}`
        }).then((data) => {
            setCustomer(data.response.data);
        }).catch((error) => {
        });
    }
    const handleChangeStatus = (status) => {
        HttpRequest({
            method: 'POST',
            url: `/api/v1/ecommerce/adminupdateorderbystatus`,
            body: {
                "orderId": order?._id,
                "orderStatus": status
            }
        }).then((data) => {
            if (data.status === 200) {
                setSuccessShow({ state: true, message: data.response.message });
                setOrder(data.response.data);
            } else {
                const message = data.response.message;
                setErrorShow({ state: true, message });
            }
        }).catch((error) => {
        });
    }
    const calculateShippingAmount = (items) => {
        if (!Array.isArray(items) || items.length === 0) {
            return 0;
        }

        const totalItemAmount = items?.reduce((total, currentItem) => {
            if (currentItem?.additionalItems && currentItem?.additionalItems.length > 0) {
                total += currentItem.additionalItems.reduce((additionalTotal, additionalItem) => {
                    additionalTotal += additionalItem.itemAmount;
                    return additionalTotal;
                }, 0);
            }

            return total;
        }, 0);

        return totalItemAmount;
    };
    const getOrderPaymentDetails = () => {
        HttpRequest({
            method: 'POST',
            url: `/api/order_payment_status`,
            body: {
                "pushyTransactionId": orderId,
                "businessNo": secureLocalStorage.getItem("businessId"),
            }
        }).then(async (data) => {
            setOrderPaymentDetails(data.response.order);

        }).catch((error) => {
        });
    }

    useEffect(() => {
        getOrderDetails();
    }, [])
    useEffect(() => {
        getOrderDetails();
    }, [orderId]);
    useEffect(() => {
        getCustomerDetails();
        if (order?._id !== '') {
            getOrderPaymentDetails();
        }
    }, [order]);
    const totalItems = order?.items?.reduce((sum, item) => {
        return sum + item.itemCount;
    }, 0);

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const getButtonProps = (orderStatus, deliveryMethod) => {
        if (!orderStatus || !deliveryMethod) {
            return null; 
        }
    
        if (orderStatus === 'Delivered') {
            return null; 
        }
    
        const statuses = {
            ship: {
                Received: { label: 'Process Order', nextStatus: 'Processing', color: '#032541' },
                Processing: { label: 'Ready To Ship', nextStatus: 'ReadyToShip', color: '#032541' },
                ReadyToShip: { label: 'Ship Order', nextStatus: 'Shipped', color: '#032541' },
                Shipped: { label: 'Mark as Delivered', nextStatus: 'Delivered', color: '#17ae7b' },
                Pending: { label: 'Mark as Received', nextStatus: 'Received', color: '#032541' },
            },
            pickup: {
                Received: { label: 'Process Order', nextStatus: 'Processing', color: '#032541' },
                Processing: { label: 'Ready for Pickup', nextStatus: 'ReadyToShip', color: '#032541' },
                ReadyToShip: { label: 'Mark as Picked Up', nextStatus: 'Delivered', color: '#032541' },
                Pending: { label: 'Mark as Received', nextStatus: 'Received', color: '#032541' },
            }
        };
    
        const deliveryStatuses = statuses[deliveryMethod];
        if (!deliveryStatuses) {
            return null; 
        }
    
        return deliveryStatuses[orderStatus] || { label: 'Mark as Received', nextStatus: 'Received', color: '#032541' };
    };
    const buttonProps = getButtonProps(order?.orderStatus, order?.deliveryMethod);
    
    return (
        <div>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <div style={{ marginTop: "4%" }}>
                <Box sx={{ display: 'flex' }}>
                    <ArrowBackIos sx={{ width: '30px', height: '50px' }} onClick={() => navigate(-1)} />
                    <Typography variant="h4" sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600", margin: '7px 15px 4px 0' }}>
                        Order Details
                    </Typography>
                </Box>
                {loading &&
                    <Box sx={{ display: 'flex', width: '100%', textAlign: 'center', alignContent: 'center', verticalAlign: 'middle', maxWidth: '774px', margin: '10% 50%' }}>
                        <CircularProgress />
                    </Box>
                }
                {!loading &&
                    <Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: '1%' }}>
                            <Box sx={{ flexDirection: "column" }}>
                                <Box>
                                    <Typography sx={{ margin: '0 0 2px', fontFamily: 'Poppins', fontWeight: 600, textAlign: 'left', color: '#032541', fontSize: '22px' }}>Order ID #{order?.orderNumber}</Typography>
                                    <Typography sx={{ margin: '2px 3px 0 0', fontFamily: 'Poppins', fontSize: '14px', textAlign: 'left', color: '#707070' }}>Ordered on {formatDate(order?.transtime)}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ marginRight: '6%' }}>
                                <Typography sx={{ margin: '0 26px 1px 0', fontFamily: 'Poppins', fontWeight: 'normal', textAlign: 'left', color: '#032541', fontSize: '14px' }}>Order Status</Typography>
                                <Box sx={{ display: 'flex', }}>
                                    <Circle sx={{ color: getOrderStatusColor(order?.orderStatus), margin: '0px 4px 5px 0', fontSize: '18px', }} />
                                    <OrderStatus orderStatus={order.orderStatus} deliveryMethod={order.deliveryMethod || 'ship'} />
                                </Box>
                            </Box>
                            <Box sx={{ marginRight: '6%' }}>
                                <Typography sx={{ margin: '0 26px 1px 0', fontFamily: 'Poppins', fontWeight: 'normal', textAlign: 'left', color: '#032541', fontSize: '14px' }}>Is a Preorder</Typography>
                                <Box sx={{ display: 'flex' }}>
                                    <Circle sx={{ color: order?.isOrderPreorder ? '#17ae7b' : '#dc3545', margin: '5px 4px 5px 0', fontSize: '18px' }} />
                                    <Typography sx={{ margin: '0 0 0 4px', fontSize: '18px', fontWeight: 600, color: order?.isOrderPreorder ? '#17ae7b' : '#dc3545' }}>{order?.isOrderPreorder ? 'Yes' : 'No'}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Divider sx={{ width: '100%', height: '1px', margin: '19.5px 0 20.5px 0.5px', backgroundColor: ' rgba(0, 0, 0, 0.12)' }} />
                        <Grid container spacing={2} >
                            <Grid item xs={3}>
                                <Box sx={{ display: 'flex', margin: '13.5px 247px 12px 10px' }}>
                                    <GroupOutlined sx={{ padding: '1.7px 0.6px 0.6px 1.7px', color: '#000' }} />
                                    <Typography sx={{ textWrap: 'nowrap', margin: '0px 47px 6px 10px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, color: '#000' }}>Customer Details</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', color: '#707070', textAlign: 'start', width: '100%', maxWidth: '45px', margin: '10px 23px 4px 0' }}>Name:</Typography>
                                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', color: '#707070', textAlign: 'start', margin: '10px 23px 4px 0' }}>{order?.customerName}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', color: '#707070', maxWidth: '45px', margin: '10px 23px 4px 0' }}>Email:</Typography>
                                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', color: '#707070', textAlign: 'start', margin: '10px 23px 4px 0' }}>{customer.email}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', color: '#707070', maxWidth: '45px', margin: '10px 23px 4px 0' }}>Phone:</Typography>
                                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', color: '#707070', textAlign: 'start', margin: '10px 23px 4px 0' }}>+{customer.phone}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={3} >
                                <Box sx={{ display: 'flex', margin: '13.5px 247px 12px 10px' }}>
                                    <ListAlt sx={{ padding: '1.7px 0.6px 0.6px 1.7px', color: '#000' }} />
                                    <Typography sx={{ textWrap: 'nowrap', margin: '0px 47px 6px 10px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, color: '#000', textAlign: 'right' }}>Order Details</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'start', textAlign: 'left' }}>
                                    <Typography sx={{ width: '100%', maxWidth: '66px', margin: '10px 23px 4px 0', fontFamily: 'Poppins', fontSize: '14px', color: '#707070', textAlign: 'left' }}>Payment:</Typography>
                                    <Typography sx={{ margin: '10px 21px 4px 51px', fontFamily: 'Poppins', fontSize: '14px', color: order?.status === "paid" ? '#17ae7b' : '#dc3545', fontWeight: 600, textAlign: 'right', textTransform: 'capitalize' }}>{order?.status}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'start', textAlign: 'left' }}>
                                    <Typography sx={{ width: '100%', maxWidth: '66px', textWrap: 'nowrap', margin: '4px 23px 4px 0', fontFamily: 'Poppins', fontSize: '14px', color: '#707070', textAlign: 'left' }}>Items:</Typography>
                                    <Typography sx={{ margin: '4px 21px 4px 51px', fontFamily: 'Poppins', fontSize: '14px', color: '#707070', textAlign: 'start' }}>{totalItems}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'start', textAlign: 'left' }}>
                                    <Typography sx={{ width: '100%', maxWidth: '66px', textWrap: 'nowrap', margin: '4px 23px 4px 0', fontFamily: 'Poppins', fontSize: '14px', color: '#707070', textAlign: 'left' }}>Amount:</Typography>
                                    <Typography sx={{ margin: '4px 21px 4px 51px', fontFamily: 'Poppins', fontSize: '14px', color: '#707070', textAlign: 'left', }}>Ksh {order?.orderAmountExclDelivery}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'start', textAlign: 'left' }}>
                                    <Typography sx={{ width: '100%', maxWidth: '66px', textWrap: 'nowrap', margin: '4px 23px 4px 0', fontFamily: 'Poppins', fontSize: '14px', color: '#707070', textAlign: 'left' }}>Delivery:</Typography>
                                    <Typography sx={{ margin: '4px 21px 4px 51px', fontFamily: 'Poppins', fontSize: '14px', color: '#707070', textAlign: 'left', textWrap: 'nowrap' }}>Ksh {order?.shippingAmount}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'start', textAlign: 'left' }}>
                                    <Typography sx={{ width: '100%', maxWidth: '66px', textWrap: 'nowrap', margin: '4px 23px 4px 0', fontFamily: 'Poppins', fontSize: '14px', color: '#707070', textAlign: 'left' }}>Customization:</Typography>
                                    <Typography sx={{ margin: '4px 21px 4px 51px', fontFamily: 'Poppins', fontSize: '14px', color: '#707070', textAlign: 'left', textWrap: 'nowrap' }}>Ksh {order?.items ? calculateShippingAmount(order?.items) : 0}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'start', textAlign: 'left' }}>
                                    <Typography sx={{ width: '100%', maxWidth: '66px', textWrap: 'nowrap', margin: '4px 23px 4px 0', fontFamily: 'Poppins', fontSize: '16px', color: '#707070', textAlign: 'left', fontWeight: 'bold' }}>Total Amount:</Typography>
                                    <Typography sx={{ margin: '4px 21px 4px 51px', fontFamily: 'Poppins', fontSize: '16px', color: '#707070', textAlign: 'left', textWrap: 'nowrap', fontWeight: 'bold' }}>Ksh {order?.transamount}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={3} >
                                <Box sx={{ display: 'flex', margin: '13.5px 247px 12px 10px' }}>
                                    <Payment sx={{ padding: '1.7px 0.6px 0.6px 1.7px', color: '#000' }} />
                                    <Typography sx={{ textWrap: 'nowrap', margin: '0px 47px 6px 10px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, color: '#000', textAlign: 'right' }}>Payment Details</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'start', textAlign: 'left' }}>
                                    <Typography sx={{ width: '100%', maxWidth: '66px', margin: '10px 33px 4px 0', fontFamily: 'Poppins', fontSize: '14px', color: '#707070', textAlign: 'left', textWrap: 'nowrap' }}>Payment Reference:</Typography>
                                    <Typography sx={{ margin: '10px 21px 4px 51px', fontFamily: 'Poppins', fontSize: '14px', color: '#707070', fontWeight: 'normal', textAlign: 'right', textTransform: 'capitalize' }}>{orderPaymentDetails?.transactionId}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'start', textAlign: 'left' }}>
                                    <Typography sx={{ width: '100%', maxWidth: '66px', textWrap: 'nowrap', margin: '4px 33px 4px 0', fontFamily: 'Poppins', fontSize: '14px', color: '#707070', textAlign: 'left' }}>Payment Method:</Typography>
                                    <Typography sx={{ margin: '4px 21px 4px 51px', fontFamily: 'Poppins', fontSize: '14px', color: '#707070', textAlign: 'start' }}>{orderPaymentDetails?.paymentMethod}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'start', textAlign: 'left' }}>
                                    <Typography sx={{ width: '100%', maxWidth: '66px', textWrap: 'nowrap', margin: '4px 33px 4px 0', fontFamily: 'Poppins', fontSize: '14px', color: '#707070', textAlign: 'left' }}>Phone No.:</Typography>
                                    <Typography sx={{ margin: '4px 21px 4px 51px', fontFamily: 'Poppins', fontSize: '14px', color: '#707070', textAlign: 'start' }}>{orderPaymentDetails?.stkPhone}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box sx={{ display: 'flex', justifyContent: 'start', textAlign: 'left', margin: '13.5px 247px 12px 10px' }}>
                                    <PinDropOutlined sx={{ padding: '1.7px 0.6px 0.6px 1.7px', color: '#000' }} />
                                    <Typography sx={{ textWrap: 'nowrap', margin: '0px 47px 6px 10px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, color: '#000' }}>Delivery Address</Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ width: '100%', maxWidth: '166px', margin: '10px 13px 4px 0', fontFamily: 'Poppins', fontSize: '14px', color: '#707070' }}>Delivery Method:</Typography>
                                    <Typography sx={{ margin: '10px 21px 4px 0px', fontFamily: 'Poppins', fontSize: '14px', color: '#707070' }}>{capitalizeFirstLetter(order?.deliveryMethod)}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ width: '100%', maxWidth: '166px', textWrap: 'nowrap', margin: '10px 13px 4px 0', fontFamily: 'Poppins', fontSize: '14px', color: '#707070' }}>{order?.deliveryMethod === 'pickup' ? 'Pickup' : 'Shipping'} Address:</Typography>
                                    <Typography sx={{ margin: '10px 21px 4px 0px', fontFamily: 'Poppins', fontSize: '14px', color: '#707070', textWrap: 'nowrap' }}>{order?.deliveryMethod === 'pickup' ? order?.pickUpLocationDetails?.locationName : order?.shippingRegionDetails?.regionDescription}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ width: '100%', maxWidth: '166px', margin: '10px 13px 4px 0', fontFamily: 'Poppins', fontSize: '14px', color: '#707070' }}>City:</Typography>
                                    <Typography sx={{ margin: '10px 21px 4px 0px', fontFamily: 'Poppins', fontSize: '14px', color: '#707070' }}>{customer?.customerpreference?.city}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ width: '100%', maxWidth: '166px', textWrap: 'nowrap', margin: '10px 13px 4px 0', fontFamily: 'Poppins', fontSize: '14px', color: '#707070' }}>Address:</Typography>
                                    <Typography sx={{ margin: '10px 21px 4px 0px', fontFamily: 'Poppins', fontSize: '14px', color: '#707070', textWrap: 'nowrap' }}>{customer?.customerpreference?.address}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider sx={{ width: '100%', height: '1px', margin: '19.5px 0 20.5px 0.5px', backgroundColor: ' rgba(0, 0, 0, 0.12)' }} />
                        <TableContainer  >
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: 'auto', height: '20px', margin: '0 265.4px 0 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, color: '#032541', textAlign: 'left' }}></TableCell>
                                        <TableCell sx={{ width: 'auto', height: '20px', margin: '0 265.4px 0 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, color: '#032541', textAlign: 'left' }}>Product</TableCell>
                                        <TableCell sx={{ width: 'auto', height: '20px', margin: '0 265.4px 0 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, color: '#032541', textAlign: 'left' }}>Variations</TableCell>
                                        <TableCell sx={{ width: 'auto', height: '20px', margin: '0 265.4px 0 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, color: '#032541', textAlign: 'left' }}>Price</TableCell>
                                        <TableCell sx={{ width: 'auto', height: '20px', margin: '0 265.4px 0 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, color: '#032541', textAlign: 'left' }}>Quantity</TableCell>
                                        <TableCell sx={{ width: 'auto', height: '20px', margin: '0 265.4px 0 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, color: '#032541', textAlign: 'left' }}>Customization</TableCell>
                                        <TableCell sx={{ width: 'auto', height: '20px', margin: '0 265.4px 0 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, color: '#032541', textAlign: 'left' }}>Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ width: '1105px', height: 'auto', margin: '75.5px 0 25px', padding: '0 0 1px' }}>
                                    {Array.isArray(order?.items) && order.items.map((row, index) => (
                                        <TableRow key={row?.name} sx={{ '&:last-child td, &:last-child th': { border: 0 }, width: '1105px', height: '70px', padding: '0 0 10px' }}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isSelected(row._id)}
                                                    inputProps={{
                                                        'aria-labelledby': `enhanced-table-checkbox-${index}`,
                                                    }}
                                                    onClick={(event) => handleClick(event, row._id)}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ display: 'flex' }}>
                                                <img src={row?.productImage ?? imagePlaceHolder} style={{ width: '50px', height: '50px', margin: '9px 26px 0 0' }} />
                                                <Typography sx={{ margin: '24px 0px 15px 1px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, color: '#000' }}>{row?.itemName}</Typography>
                                            </TableCell>
                                            <TableCell sx={{ margin: '24px 0px 15px 1px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', color: '#707070' }}>{row?.tags.join(', ')}</TableCell>
                                            <TableCell sx={{ margin: '24px 0px 15px 1px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', color: '#707070' }}>Ksh {row?.itemAmount / row?.itemCount}</TableCell>
                                            <TableCell sx={{ margin: '24px 0px 15px 1px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', color: '#707070' }}>{row?.itemCount}</TableCell>
                                            <TableCell sx={{ margin: '24px 0px 15px 1px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', color: '#707070' }}>
                                                {row?.additionalItems.length > 0 ? row?.additionalItems.map(item => `${item.itemName}: ${item.isCustomizableValue}`).join(', ') : 'None'}
                                            </TableCell>
                                            <TableCell sx={{ margin: '24px 0px 15px 1px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', color: '#707070' }}>Ksh {row?.itemAmount}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {order?.deliveryMethod && order?.status === 'paid' && (
                            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                               
                                {buttonProps && (
                                    <Button
                                        onClick={() => handleChangeStatus(buttonProps.nextStatus)}
                                        variant="contained"
                                        sx={{
                                            textTransform: 'capitalize', width: '150px', height: '45px',
                                            margin: '25px 150px 0 100px', padding: '13px 26px 12px', borderRadius: '5px',
                                            backgroundColor: buttonProps.color, fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500,
                                            textAlign: 'center', color: '#fff', textWrap: 'nowrap'
                                        }}
                                    >
                                        {buttonProps.label}
                                    </Button>
                                )}
                            </Box>
                        )}

                    </Box>
                }
            </div>
        </div>
    )
}
export default OrderDetails;